import { CoreWebVitalsMetric, Metric } from "~/api/contracts/models";

const config = [
  {
    metric: Metric.WAFBlock,
    min: 50,
    max: 100,
    step: 5,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 70,
  },
  {
    metric: Metric.WAFOwaspBlock,
    min: 50,
    max: 100,
    step: 5,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 80,
  },
  {
    metric: Metric.TTFBFromChina,
    min: 100,
    max: 1500,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 400,
  },
  {
    metric: Metric.GlobalDNSResolveTime,
    min: 100,
    max: 300,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 150,
  },
  {
    metric: Metric.GooglebotTTFB,
    min: 200,
    max: 700,
    step: 100,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 500,
  },
  {
    metric: Metric.GlobalNetworkOverhead,
    min: 250,
    max: 600,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 400,
  },
  {
    metric: Metric.TTFB,
    min: 100,
    max: 1500,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 500,
  },
  {
    metric: Metric.ThirtyDaysAvailabilityForApplication,
    min: 0,
    max: 5,
    step: 1,
    outValueMapping: (val) =>
      [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].at(val),
    inValueMapping: (val) =>
      [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].indexOf(val),
    defaultValue: 2,
  },
  {
    metric: Metric.ThirtyDaysAvailabilityForInfrastructure,
    min: 0,
    max: 5,
    step: 1,
    outValueMapping: (val) =>
      [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].at(val),
    inValueMapping: (val) =>
      [99.5, 99.9, 99.95, 99.99, 99.995, 99.999].indexOf(val),
    defaultValue: 2,
  },
  {
    metric: Metric.TTFBNearOrigin,
    min: 100,
    max: 1500,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 400,
  },
  {
    metric: CoreWebVitalsMetric.PageSpeed,
    min: 10,
    max: 100,
    step: 5,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 80,
  },
  {
    metric: CoreWebVitalsMetric.FirstContentfulPaint,
    min: 100,
    max: 2000,
    step: 100,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 100,
  },
  {
    metric: CoreWebVitalsMetric.CumulativeLayoutShift,
    min: 0,
    max: 0.5,
    step: 0.05,
    outValueMapping: (val) => Math.round((val + Number.EPSILON) * 100) / 100,
    inValueMapping: (val) => Math.round((val + Number.EPSILON) * 100) / 100,
    defaultValue: 0,
  },
  {
    metric: CoreWebVitalsMetric.LargestContentfulPaint,
    min: 100,
    max: 3000,
    step: 100,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 100,
  },
  {
    metric: CoreWebVitalsMetric.PageWeight,
    min: 0.5,
    max: 5,
    step: 0.5,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 0.5,
  },
  {
    metric: CoreWebVitalsMetric.TotalBlockingTime,
    min: 0,
    max: 500,
    step: 50,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 0,
  },
  {
    metric: Metric.WAFRequestsBlocked,
    min: 1,
    max: 20,
    step: 1,
    outValueMapping: (val) => val,
    inValueMapping: (val) => val,
    defaultValue: 5,
  },
];

export default () => config;
