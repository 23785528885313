<template>
    <ClientOnly>
        <Dialog v-model:visible="isModalVisible" :style="{ width: '900px' }" :header="getHeader()" :modal="true"
            @update:visible="hideDialog" @hide="hideDialog" class="p-fluid p-input-filled">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                    <div class="field">
                        <label for="metricName" class="block">Metric</label>

                        <Select :disabled="isEdit" id="metricName" v-model="contextBusinessGoal.metric"
                            :options="metrics" optionGroupChildren="items" optionGroupLabel="label" optionValue="value"
                            placeholder="Select a metric" class="mt-2 w-full"
                            :class="{ 'p-invalid': vuelidate?.metric?.$errors.length }" @change="vuelidate.$reset">
                            <template #optiongroup="slotProps">
                                <div class="p-dropdown-metric">
                                    <i :class="slotProps.option.icon"></i>
                                    <span>{{ slotProps.option.label }}</span>
                                </div>
                            </template>
                            <template #value="slotProps">
                                <div class="p-dropdown-metric" v-if="slotProps.value">
                                    <i :class="getMetricIcon(slotProps.value)"></i>
                                    <span>{{ getMetricLabel(slotProps.value) }}</span>
                                </div>
                                <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="p-dropdown-metric">
                                    <span>{{ slotProps.option.label }}</span>
                                </div>
                            </template>
                        </Select>

                        <InputValidationMessage name="metric" :vuelidate="vuelidate" />
                    </div>
                </div>
                <div class="col-span-4" v-if="contextBusinessGoal.metric == 'CoreWebVitals'">
                    <div class="field">
                        <label for="coreWebVitalsMetricName" class="block">Metric</label>
                        <Select :disabled="isEdit" id="coreWebVitalsMetricName"
                            v-model="contextBusinessGoal.coreWebVitalsMetric" :options="coreWebVitalsMetrics"
                            option-value="value" option-label="label" placeholder="Select a metric" class="mt-2 w-full"
                            :class="{ 'p-invalid': vuelidate?.coreWebVitalsMetric?.$errors.length }">
                            <template #value="slotProps">
                                <div class="p-dropdown-metric" v-if="slotProps.value">
                                    <span>{{ getCoreWebVitalMetricLabel(slotProps.value) }}</span>
                                </div>
                                <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="p-dropdown-metric">
                                    <span>{{ slotProps.option.label }}</span>
                                </div>
                            </template>
                        </Select>
                        <InputValidationMessage name="coreWebVitalsMetric" :vuelidate="vuelidate" />
                    </div>
                </div>
                <div class="col-span-2">
                    <BusinessGoalValueSelector v-model="contextBusinessGoal.value" :goal="contextBusinessGoal"
                        :vuelidate="vuelidate" />
                </div>
                <div class="col-span-4"
                    v-if="contextBusinessGoal.metric && contextBusinessGoal.metric != 'WAFRequestsBlocked'">
                    <div class="field">
                        <label for="hostName" class="block">Host name</label>
                        <div class="mt-2">
                            <HostNamePicker id="hostInclude" :unique="isUnique()"
                                :host-names="contextBusinessGoal.hostNameValues"
                                :project-id="contextBusinessGoal.projectId" :disabled="isEdit" :min-stage="getMinStage"
                                class="mt-2 w-full" :class="{ 'p-invalid': vuelidate?.hostNames?.$errors.length }"
                                @hostNamesChanged="setHostNames" required="true" />
                        </div>

                        <InputValidationMessage name="hostNames" :vuelidate="vuelidate" />
                    </div>
                </div>
                <div class="col-span-4"
                    v-if="contextBusinessGoal.metric == 'CoreWebVitals' && contextBusinessGoal.hostNames">
                    <div class="field">
                        <label for="hostName" class="block">Path</label>

                        <CoreWebVitalsPathPicker :disabled="isEdit" @pathSelected="setPath" class="mt-2"
                            :endpoint="contextBusinessGoal.hostNames[0]" :path="contextBusinessGoal.path" />
                    </div>
                </div>
                <div class="col-span-2"
                    v-if="contextBusinessGoal.metric == 'CoreWebVitals' && contextBusinessGoal.hostNames">
                    <div class="field">
                        <label for="hostName" class="block">Device</label>

                        <Select id="value" fluid class="mt-2" :disabled="isEdit" v-model="contextBusinessGoal.device"
                            :options="devices" placeholder="Select device" option-label="label" option-value="value"
                            :class="{ 'p-invalid': vuelidate?.device?.$errors.length }" />

                        <InputValidationMessage name="device" :vuelidate="vuelidate" />
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-2" v-if="contextBusinessGoal.metric == 'TTFB'">
                <div class="col-span-4">
                    <div class="field">
                        <label for="geoScope" class="block">Geo scope</label>
                        <Select :disabled="isEdit" id="geoScope" v-model="contextBusinessGoal.geoScope"
                            :options="geoScopes" :fluid="true" class="mt-2" placeholder="Select geo scope"
                            option-label="label" option-value="value" />
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="field">
                        <label for="urlScopeOperator" class="block">Url scope operator</label>
                        <Select :disabled="isEdit" id="urlScopeOperator" v-model="contextBusinessGoal.urlScopeOperator"
                            :class="{ 'p-invalid': vuelidate?.urlScopeOperator?.$errors.length }"
                            :options="urlScopeOperators" option-label="label" option-value="value" :fluid="true"
                            class="mt-2" placeholder="Select url scope operator" />
                        <InputValidationMessage name="urlScopeOperator" :vuelidate="vuelidate" />
                    </div>
                </div>
                <div class="col-span-4">
                    <div class="field">
                        <label for="urlScope" class="block">Url</label>
                        <InputText :disabled="isEdit" id="urlScope" v-model.trim="contextBusinessGoal.urlScope"
                            :class="{ 'p-invalid': vuelidate?.urlScope?.$errors.length }" required="true" :fluid="true"
                            class="mt-2" />
                        <InputValidationMessage name="urlScope" :vuelidate="vuelidate" />
                    </div>
                </div>
            </div>
            <div v-if="isEdit && contextBusinessGoal.id && chartData && chartData.datasets[0].data.length > 0">
                <Line :data="chartData" :options="options" v-if="chartData" style="height: 400px" />
            </div>
            <div v-if="isEdit && !chartData">
                No data to show
            </div>

            <template #footer>
                <div class="c-dialog__footer pt-4">
                    <div class="c-dialog__footer--end flex flex-row gap-4">
                        <Button label="Refresh data" v-if="isEdit && userProfile?.isAdmin" icon="pi pi-refresh"
                            class="p-button-outlined" id="refresh-btn" @click="runBusinessGoal"
                            @contextmenu.prevent="copyToClipboard(contextBusinessGoal.query!)" />
                        <Button label="Save" icon="pi pi-check" class="p-button" @click="saveBusinessGoal" />
                    </div>
                </div>
            </template>
        </Dialog>
        <Dialog v-model:visible="showOverwriteModal" :style="{ width: '450px' }" header="Confirm overwrite"
            :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-4" style="font-size: 2rem" />
                <span>
                    Such business goal already exists. <br />

                    <span v-if="existingBusinessGoal.latestTarget?.value != contextBusinessGoal.value">
                        Do you wish to overwrite it's target value from {{ existingBusinessGoal.latestTarget?.value }}
                        to {{
                            contextBusinessGoal.value }}?
                    </span>

                </span>
            </div>
            <template #footer>
                <div class="c-dialog__footer--end flex flex-row gap-4">
                    <Button label="Cancel" icon="pi pi-times" class="p-button-outlined" @click="cancelOverwrite" />
                    <Button label="Yes" icon="pi pi-check" class="p-button" @click="acceptOverwrite"
                        v-if="existingBusinessGoal.latestTarget?.value != contextBusinessGoal.value" />
                </div>
            </template>
        </Dialog>
    </ClientOnly>
</template>

<script lang="ts">
import { BusinessGoalDataDto, BusinessGoalDto, CoreWebVitalsMetric, CreateBusinessGoalRequest, Metric, UpdateBusinessGoalRequest } from '@/api/contracts/models';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';

import HostNamePicker from '@/components/shared/HostNamePicker.vue'
import CoreWebVitalsPathPicker from '@/components/endpoints/CoreWebVitalsPathPicker.vue'
import InputValidationMessage from '@/components/shared/InputValidationMessage.vue'
import BusinessGoalValueSelector from '@/components/business-goals/BusinessGoalValueSelector.vue';

import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useParams } from '@/composables/utils/useParams';
import { useQueryClient } from '@tanstack/vue-query';
import { useMetrics } from '@/composables/data/useMetrics'
import { useMetricUnitLookup } from '@/composables/data/useMetricUnitLookup';
import { useMetricOperators } from '@/composables/data/useMetricOperators';
import { useGeoScopes } from '@/composables/data/useGeoScopes';
import { useBusinessGoalCreate } from '@/composables/mutations/useBusinessGoalCreate';

import { useUrlScopeOperators } from '@/composables/data/useUrlScopeOperators';
import { v4 as uuidv4 } from 'uuid'
import { useBusinessGoalUpdate } from '@/composables/mutations/useBusinessGoalUpdate';
import { useBusinessGoalDataQuery } from '@/composables/queries/useBusinessGoalDataQuery';

import dayjs from 'dayjs';
import { Line } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Chart, TimeScale } from 'chart.js';

import copy from 'copy-to-clipboard'

import 'chartjs-adapter-moment';

Chart.register(annotationPlugin);

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

import { useProjectBusinessGoalsWithData } from '@/composables/useProjectBusinessGoalsWithData';
import { useEndpointsQuery } from '@/composables/queries/useEndpointsQuery';
import { useCoreWebVitalsMetrics } from '@/composables/data/useCoreWebVitalsMetrics';
import { useDevices } from '@/composables/data/useDevices';

import useAvailabilityValues from '@/composables/data/useAvailabilityValues';

import { useRecommendationBusinessGoalImpacts } from '@/composables/useRecommendationBusinessGoalImpacts';

import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { useMinValueValidator, useRequiredIfValidator, useRequiredValidator } from '@/validation'
import { useDataSynchronizationApi } from '~/composables/api/useDataSynchronizationApi';

import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery';
import { useMetricIcon } from '~/composables/data/useMetricIcon';


export default defineComponent({
    name: 'BusinessGoalModal',

    components: {
        Button,
        InputText,
        InputNumber,
        Dropdown,
        Dialog,
        Line,
        HostNamePicker,
        CoreWebVitalsPathPicker,
        InputValidationMessage,
        BusinessGoalValueSelector
    },

    props: {
        businessGoal: {
            type: Object as PropType<BusinessGoalDto>
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        isDeleteable: {
            type: Boolean,
            default: true
        }
    },

    emits: [
        'hideDialog'
    ],
    setup(props, context) {
        const { projectId } = useParams()

        const hideDialog = () => {
            context.emit('hideDialog')
        }

        const contextBusinessGoal = ref(props.businessGoal ? { ...props.businessGoal } : {} as BusinessGoalDto)

        const isEdit = ref(false)
        const showOverwriteModal = ref(false)
        const forcedOverwrite = ref(false)

        const contextBusinessGoalId = ref(props.businessGoal?.id ? props.businessGoal?.id : '')

        const app = useNuxtApp()

        const required = useRequiredValidator(app.$i18n)

        const rules = {
            metric: { required },
            value: { required },
            urlScope: {
                required: useRequiredIfValidator(app.$i18n, (x: any, y: any) => {
                    return y.urlScopeOperator != null
                })
            },
            urlScopeOperator: { required: useRequiredIfValidator(app.$i18n, (x: any, y: any) => y.urlScope && y.urlScope.length > 0) },
            hostNames: { required: useRequiredIfValidator(app.$i18n, (x: any) => isUnique()) },
            coreWebVitalsMetric: { required: useRequiredIfValidator(app.$i18n, (x: any, y: any) => y.metric == Metric.CoreWebVitals) },
            device: { required: useRequiredIfValidator(app.$i18n, (x: any, y: any) => y.metric == Metric.CoreWebVitals) }
        } as unknown as ValidationArgs<BusinessGoalDto>

        const vuelidate = useVuelidate(rules, contextBusinessGoal)
        watch(
            () => props.businessGoal,
            () => {
                contextBusinessGoal.value = props.businessGoal ? { ...props.businessGoal } as BusinessGoalDto : {} as BusinessGoalDto
                isEdit.value = props.businessGoal?.id ? true : false
                contextBusinessGoalId.value = props.businessGoal?.id ? props.businessGoal?.id : ''
                vuelidate.value.$reset()
            },
            {
                immediate: true,
                deep: true
            })

        const isModalVisible = ref(props.isVisible)

        const isSubmitted = ref(false)
        watch(
            () => props.isVisible,
            () => {
                isModalVisible.value = props.isVisible,
                    isSubmitted.value = false
                forcedOverwrite.value = false
                showOverwriteModal.value = false
            },
            {
                immediate: true
            }
        )

        const metrics = useMetrics()
        const coreWebVitalsMetrics = useCoreWebVitalsMetrics()
        const devices = useDevices()

        const getMetricLabel = (metricValue: any) => {
            for (let metricGroup of metrics.value!) {
                const result = metricGroup.items?.find(x => x.value == metricValue)

                if (result) {
                    return result.label
                }
            }

            return ''
        }

        const getCoreWebVitalMetricLabel = (metricValue: any) => {
            return coreWebVitalsMetrics.value?.filter(x => x.value == metricValue)[0]?.label
        }

        const metricOperators = useMetricOperators()
        const geoScopes = useGeoScopes()
        const urlScopeOperators = useUrlScopeOperators()

        const createNewBusinessGoalMutation = useBusinessGoalCreate(
            useQueryClient()
        )

        const updateBusinessGoalMutation = useBusinessGoalUpdate(
            useQueryClient()
        )

        const existingBusinessGoals = useProjectBusinessGoalsWithData(projectId)

        const acceptOverwrite = () => {
            forcedOverwrite.value = true
            showOverwriteModal.value = false

            saveBusinessGoal()
        }

        const { data: endpoints } = useEndpointsQuery(projectId)

        const existingBusinessGoal = ref({} as BusinessGoalDto)

        const hostNames = computed(() => endpoints.value && existingBusinessGoal.value ?
            endpoints.value
                .filter(x => x.projectId == projectId.value)
                .map(x => x.hostName) : [])

        const saveBusinessGoal = async () => {
            const isFormCorrect = await vuelidate.value.$validate()

            if (!isFormCorrect) return

            isSubmitted.value = true

            const metricUnit = getMetricUnit(contextBusinessGoal.value.metric)

            if (isEdit.value) {
                const request = {
                    id: contextBusinessGoal.value.id,
                    value: contextBusinessGoal.value.value
                } as UpdateBusinessGoalRequest

                updateBusinessGoalMutation.mutate(request)
            }
            else if (existingBusinessGoal.value?.id && forcedOverwrite.value) {
                const request = {
                    id: existingBusinessGoal.value.id,
                    value: contextBusinessGoal.value.value
                } as UpdateBusinessGoalRequest

                updateBusinessGoalMutation.mutate(request)
            }
            else {
                const request = {
                    ...contextBusinessGoal.value,
                    id: uuidv4(),
                    metricUnit: metricUnit,
                    projectId: projectId.value,
                    value: contextBusinessGoal.value.value
                } as CreateBusinessGoalRequest

                const existingBusinessGoalsOfExactSameType = existingBusinessGoals.value?.filter(x =>
                    x.metric == request.metric &&
                    x.coreWebVitalsMetric == request.coreWebVitalsMetric &&
                    x.geoScope == request.geoScope &&
                    x.hostNames?.isEquivalent(request.hostNames) &&
                    x.urlScope == request.urlScope &&
                    x.urlScopeOperator == request.urlScopeOperator &&
                    x.path == request.path &&
                    x.device == request.device)

                if (existingBusinessGoalsOfExactSameType && existingBusinessGoalsOfExactSameType.length > 0) {
                    showOverwriteModal.value = true
                    existingBusinessGoal.value = { ...existingBusinessGoalsOfExactSameType![0] }

                    return;
                }

                createNewBusinessGoalMutation.mutate(request)
            }

            hideDialog()
        }

        const getMetricIcon = (metric: Metric) => useMetricIcon(metric)



        const { data: businessGoalData } = useBusinessGoalDataQuery(contextBusinessGoalId)
        const businessGoalRecommendationImpacts = useRecommendationBusinessGoalImpacts(contextBusinessGoalId)

        const options = {
            responsive: false,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                },
                x: {
                    type: 'time',
                    ticks: {
                        autoSkip: false,
                        maxTicksLimit: 5,
                        major: {
                            enabled: true
                        }
                    },
                    grid: {
                        display: false
                    },
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                    },
                    pan: {
                        enabled: true,
                        mode: 'x'
                    },
                    limits: {
                        x:
                        {
                            min: dayjs((new Date().getDate()) - 30).valueOf(),
                            max: dayjs((new Date())).valueOf(),
                            minRange: 30 * 24 * 3600 * 1000,

                        }
                    }
                }
            }
        }

        const chartData = computed(() => {
            if (!contextBusinessGoal.value || (!businessGoalData.value || businessGoalData.value.length == 0)) return null

            const orderedData = _orderBy(businessGoalData.value, x => x.date) as BusinessGoalDataDto[]

            if (!orderedData || orderedData.length < 1) return null;

            (options.scales.x as any).ticks = {
                maxTicksLimit: 5,
                major: {
                    enabled: true
                }
            };

            (options.plugins.zoom as any).limits.x.min = dayjs(orderedData[0].date).valueOf();
            (options.plugins.zoom as any).limits.x.max = dayjs(orderedData[orderedData.length - 1].date).valueOf();
            (options.plugins.zoom as any).limits.x.minRange = 30 * 24 * 3600 * 1000;

            const data = orderedData.filter(el => el.metricData != undefined && el.metricData != null).map(el => (el.metricData as any).value)

            const orderedTargets = _orderBy(contextBusinessGoal.value.targets, x => x.effectiveFrom, 'desc')

            if (contextBusinessGoal.value!.metric == Metric.TTFB ||
                contextBusinessGoal.value!.metric == Metric.TTFBFromChina ||
                contextBusinessGoal.value!.metric == Metric.TTFBNearOrigin ||
                contextBusinessGoal.value!.metric == Metric.GlobalNetworkOverhead ||
                contextBusinessGoal.value!.metric == Metric.GlobalDNSResolveTime ||
                contextBusinessGoal.value!.metric == Metric.GooglebotTTFB) {
                (options.scales.y as any).min = 0;
                (options.scales.y as any).max = (options.scales.y as any).suggestedMax;
                (options.scales.y as any).ticks = {
                    callback: (label) => `${label}ms`
                }
            }
            else if (contextBusinessGoal.value!.metric == Metric.CoreWebVitals) {
                (options.scales.y as any).min = 0;
                (options.scales.y as any).max = (options.scales.y as any).suggestedMax;

                switch (contextBusinessGoal.value.coreWebVitalsMetric) {
                    case CoreWebVitalsMetric.CumulativeLayoutShift:
                    case CoreWebVitalsMetric.PageSpeed:
                        (options.scales.y as any).ticks = {
                            callback: (label) => `${label}`
                        }
                        break;
                    case CoreWebVitalsMetric.PageWeight:
                        (options.scales.y as any).ticks = {
                            callback: (label) => `${label} MB`
                        }
                        break;
                    default:
                        (options.scales.y as any).ticks = {
                            callback: (label) => `${label}ms`
                        }
                }
            }
            else {
                if (contextBusinessGoal.value!.metric == Metric.WAFBlock || contextBusinessGoal.value!.metric == Metric.WAFOwaspBlock) {
                    (options.scales.y as any).min = 0;
                    (options.scales.y as any).max = 100;
                }
                else if (contextBusinessGoal.value!.metric == Metric.WAFRequestsBlocked) {
                    (options.scales.y as any).min = 0;
                }
                else {
                    if (data) {
                        const minData = Math.min(...data);
                        (options.scales.y as any).min = Math.min(minData, 99.5);
                    }
                }

                (options.scales.y as any).ticks = {
                    callback: (label) => `${label.toFixed(2)}%`
                }
            }

            if (businessGoalRecommendationImpacts && businessGoalRecommendationImpacts.value && businessGoalRecommendationImpacts.value.length > 0) {
                var annotations = [] as any[]

                const reverseOrderedData = _orderBy(businessGoalData.value, x => x.date, 'desc') as BusinessGoalDataDto[]

                for (const link of businessGoalRecommendationImpacts.value) {
                    var nearestValue = dayjs(link.date).format('YYYY-MM-DD');

                    for (const dataEl of reverseOrderedData) {
                        if (dayjs(link.date) >= dayjs(dataEl.date)) {
                            nearestValue = dayjs(dataEl.date).format('YYYY-MM-DD');
                            break
                        }
                    }

                    annotations.push(
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x",
                            value: nearestValue,
                            borderColor: "#005088",
                            label: {
                                content: link.recommendationFriendlyId,
                                display: true,
                                position: "start",
                                color: '#fff',
                                backgroundColor: '#005088',
                                margin: 0,
                                yAdjust: -8
                            }
                        }
                    )
                }
                (options.plugins as any).annotation = {
                    annotations: annotations
                }
            }

            const result = {
                labels: orderedData.map(d => {
                    return dayjs(d.date).format('YYYY-MM-DD')
                }),
                datasets: [
                    {
                        label: 'TTFB - Goal',
                        data: orderedData.map(d => {
                            let lastTarget = 0
                            for (const target of orderedTargets) {
                                if (target.effectiveFrom <= d.date!) {
                                    return target.value
                                }

                                lastTarget = target.value
                            }

                            return lastTarget;
                        }),
                        backgroundColor: '#E08947',
                        borderColor: '#E08947',
                        borderWidth: 4

                    },
                    {
                        label: 'Metric',
                        data: data,
                        fill: {
                            target: 'origin',
                            above: '#2F6081'    // And blue below the origin
                        },
                        borderColor: '#2F6081'
                    },
                ]
            }

            return result
        })

        const cancelOverwrite = () => {
            forcedOverwrite.value = false
            showOverwriteModal.value = false
        }

        const setHostNames = (value) => {
            contextBusinessGoal.value.hostNames = value
        }

        const refEndpoint = ref('')
        watch(
            () => contextBusinessGoal.value,
            () => {
                if (contextBusinessGoal.value.hostNames && contextBusinessGoal.value.hostNames.length > 0) {
                    refEndpoint.value = contextBusinessGoal.value.hostNames[0]
                }
            }, {
            immediate: true,
            deep: true
        }
        )
        const metricUnitsLookup = useMetricUnitLookup()

        const getMetricUnit = (name: any) => {
            return metricUnitsLookup.value ? metricUnitsLookup.value[name] : null
        }
        const availabilityValues = useAvailabilityValues()
        const getHeader = () => {
            if (!isEdit.value) {
                return "Business Goal"
            }

            if (contextBusinessGoal.value.metric == 'CoreWebVitals') {
                return getCoreWebVitalMetricLabel(contextBusinessGoal.value.coreWebVitalsMetric) + " " + contextBusinessGoal.value.value + ' ' + getMetricUnit(contextBusinessGoal.value.coreWebVitalsMetric!)!;
            }

            if (contextBusinessGoal.value.metric == Metric.ThirtyDaysAvailabilityForApplication || contextBusinessGoal.value.metric == Metric.ThirtyDaysAvailabilityForInfrastructure) {
                var availabilityValue = availabilityValues.find(x => _toNumber(x.value) == contextBusinessGoal.value.value)

                return getMetricLabel(contextBusinessGoal.value.metric) + " " + availabilityValue?.label;
            }

            return getMetricLabel(contextBusinessGoal.value.metric) + " " + contextBusinessGoal.value!.value + getMetricUnit(contextBusinessGoal.value.metric!)!;
        }

        const isUnique = () => {
            if (contextBusinessGoal.value.metric == Metric.GlobalNetworkOverhead ||
                contextBusinessGoal.value.metric == Metric.GlobalDNSResolveTime ||
                contextBusinessGoal.value.metric == Metric.CoreWebVitals ||
                contextBusinessGoal.value.metric == Metric.TTFBFromChina ||
                contextBusinessGoal.value.metric == Metric.TTFBNearOrigin) {
                return true
            }

            return false
        }

        const getMinStage = computed(() => {
            if (contextBusinessGoal.value.metric) {
                switch (contextBusinessGoal.value.metric) {
                    case Metric.WAFBlock:
                    case Metric.WAFOwaspBlock:
                    case Metric.ThirtyDaysAvailabilityForInfrastructure:
                    case Metric.TTFB:
                        // case Metric.CoreWebVitals:
                        return 1;
                    default:
                        return 0
                }
            }

            return 0
        })

        const setPath = (path: any) => {
            contextBusinessGoal.value.path = path;
        }

        const queryClient = useQueryClient()
        const api = useDataSynchronizationApi()
        const { $toast } = useNuxtApp();

        const copyToClipboard = (query: string) => {
            copy(query)

            $toast.success('Query copied to clipboard!')
        }

        const runBusinessGoal = () => {
            const button = document.getElementById('refresh-btn')

            button?.setAttribute('disabled', 'disabled')

            api.updateGoalData(contextBusinessGoalId.value).then(response => {

                $toast.success('Goal data fetched')

                queryClient.invalidateQueries()

                button?.removeAttribute('disabled')
            })
                .catch(error => $toast.error('Something went wrong. Please try again in few minutes'))
        }

        const { data: userProfile } = useUserProfileQuery()

        return {
            hideDialog,
            saveBusinessGoal,
            contextBusinessGoal,
            isModalVisible,
            isSubmitted,
            geoScopes,
            metrics,
            coreWebVitalsMetrics,
            metricOperators,
            urlScopeOperators,
            isEdit,
            hostNames,
            getMetricIcon,
            devices,
            chartData,
            options,
            getMetricLabel,
            showOverwriteModal,
            acceptOverwrite,
            existingBusinessGoal,
            cancelOverwrite,
            setHostNames,
            getHeader,
            isUnique,
            getMinStage,
            getCoreWebVitalMetricLabel,
            setPath,
            vuelidate,
            runBusinessGoal,
            copyToClipboard,
            userProfile
        }
    }
})
</script>

<style lang="scss">
.p-dropdown-metric {

    i {
        margin-right: 8px;
    }
}
</style>