import { Metric } from "~/api/contracts/models";

export function useMetricIcon(metric: Metric) {
  switch (metric) {
    case Metric.TTFB:
    case Metric.TTFBFromChina:
    case Metric.TTFBNearOrigin:
    case Metric.GlobalNetworkOverhead:
    case Metric.GlobalDNSResolveTime:
    case Metric.GooglebotTTFB:
    case Metric.CoreWebVitals:
      return "pi pi-stopwatch";
    case Metric.WAFBlock:
    case Metric.WAFOwaspBlock:
    case Metric.WAFRequestsBlocked:
      return "pi pi-shield";
    case Metric.ThirtyDaysAvailabilityForApplication:
    case Metric.ThirtyDaysAvailabilityForInfrastructure:
      return "pi pi-eye";
    default:
      return "";
  }
}
