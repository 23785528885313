<template>
    <Select v-if="!disabled" id="pathPicker" :disabled="disabled" v-model="contextPath" @change="handlePathChange"
        :options="paths" fluid option-value="value" option-label="label" placeholder="/" class="mt-2" />
    <InputText v-if="disabled" :disabled="disabled" v-model="contextPath" placeholder="/" fluid class="mt-2">
    </InputText>
</template>

<script lang="ts">
import { useEndpointPageSpeedPathsQuery } from '@/composables/queries/useEndpointPageSpeedPathsQuery';
import { useParams } from '@/composables/utils/useParams';
import { defineComponent, ref, watch } from 'vue';

import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext';

export default defineComponent({
    components: {
        Dropdown,
        InputText
    },
    props: {
        endpoint: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        path: {
            type: String,
            required: false
        }
    },
    setup(props, context) {
        const { projectId } = useParams()
        const contextEndpoint = ref(props.endpoint ? props.endpoint : '')
        const contextPath = ref(props.path ? props.path : '')

        const { data: paths } = useEndpointPageSpeedPathsQuery(projectId, contextEndpoint)

        watch(
            () => props.endpoint,
            () => {
                contextEndpoint.value = props.endpoint ? props.endpoint : ''
                paths.value = []
            },
            { immediate: true, deep: true }
        )


        const handlePathChange = (value) => {
            context.emit('pathSelected', value.value)
        }

        return {
            paths,
            handlePathChange,
            contextPath
        }
    }
})
</script>